<template>
	<div class="myBet-inner-content" >
		<div class="game_detail" v-for="(betslip, index) in betData" :key="index">
			<div class="top" :class="collapse_closed[betslip.betslip_id] !== true ? 'collapse-active' : ''">
				<div class="left inner-left">
					<div class="title">
						<div class="title-area-left" :style="betslip.is_mission_betslip ? gridColumns : ''">
							<div @click="toggleFavourite(betslip.betslip_id)" v-if="betslip.favourite" class="pin-icon">
								<svg  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve" style="rotate: 45deg; cursor: pointer;">
									<g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10;
										fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
										<path d="M 84.303 82.191 l -6.492 -6.492 l -6.492 -6.492 c -1.077 -1.087 -2.175 -2.153 -3.235 -3.257 c -0.016 -0.009 -0.031 -0.017
										-0.047 -0.025 l -2.154 -2.154 L 90 39.653 l -1.056 -1.056 c -9.367 -9.368 -23.457 -12.705 -36.139 -8.632 l -7.345 -7.344 c 0.929 -7.947
										-1.815 -15.958 -7.422 -21.565 L 36.983 0 L 0 36.982 l 1.057 1.056 c 5.606 5.606 13.614 8.353 21.565 7.422 l 7.345 7.345 c -4.073 12.681
										-0.737 26.772 8.631 36.139 L 39.653 90 l 24.117 -24.117 l 2.155 2.155 c 0.008 0.015 0.016 0.031 0.025 0.046 c 1.1 1.058 2.164 2.152 3.247
										3.226 l 8.081 8.081 l 0 0 l 4.912 4.912 l 3.246 3.246 c 1.403 0.761 2.796 1.532 4.302 2.19 c -0.658 -1.506 -1.429 -2.899 -2.19 -4.302 L
										84.303 82.191 z M 33.086 52.897 l 0.311 -0.886 l -9.714 -9.714 l -0.742 0.108 c -6.763 0.987 -13.633 -1.042 -18.681 -5.459 L 36.948 4.26
										c 4.415 5.047 6.447 11.92 5.458 18.68 l -0.108 0.742 l 9.714 9.714 l 0.886 -0.311 c 11.361 -3.984 24.084 -1.387 32.853 6.593 L 39.678 85.75
										C 31.698 76.981 29.102 64.254 33.086 52.897 z" style="stroke: none; stroke-width: 2; stroke-dasharray: none; stroke-linecap: butt;
										stroke-linejoin: miter; stroke-miterlimit: 10; fill: #63A246; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) "
										stroke-linecap="round" />
									</g>
								</svg>
							</div>
							<div @click="toggleFavourite(betslip.betslip_id)" class="pin-icon" v-else >
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve" style="rotate: 90deg; cursor: pointer;">
									<g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10;
										fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
										<path d="M 84.303 82.191 l -6.492 -6.492 l -6.492 -6.492 c -1.077 -1.087 -2.175 -2.153 -3.235 -3.257 c -0.016 -0.009 -0.031 -0.017
										-0.047 -0.025 l -2.154 -2.154 L 90 39.653 l -1.056 -1.056 c -9.367 -9.368 -23.457 -12.705 -36.139 -8.632 l -7.345 -7.344 c 0.929 -7.947
										-1.815 -15.958 -7.422 -21.565 L 36.983 0 L 0 36.982 l 1.057 1.056 c 5.606 5.606 13.614 8.353 21.565 7.422 l 7.345 7.345 c -4.073 12.681
										-0.737 26.772 8.631 36.139 L 39.653 90 l 24.117 -24.117 l 2.155 2.155 c 0.008 0.015 0.016 0.031 0.025 0.046 c 1.1 1.058 2.164 2.152 3.247
										3.226 l 8.081 8.081 l 0 0 l 4.912 4.912 l 3.246 3.246 c 1.403 0.761 2.796 1.532 4.302 2.19 c -0.658 -1.506 -1.429 -2.899 -2.19 -4.302 L
										84.303 82.191 z M 33.086 52.897 l 0.311 -0.886 l -9.714 -9.714 l -0.742 0.108 c -6.763 0.987 -13.633 -1.042 -18.681 -5.459 L 36.948 4.26
										c 4.415 5.047 6.447 11.92 5.458 18.68 l -0.108 0.742 l 9.714 9.714 l 0.886 -0.311 c 11.361 -3.984 24.084 -1.387 32.853 6.593 L 39.678 85.75
										C 31.698 76.981 29.102 64.254 33.086 52.897 z" style="stroke: none; stroke-width: 2; stroke-dasharray: none; stroke-linecap: butt;
										stroke-linejoin: miter; stroke-miterlimit: 10; fill: #fff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) "
										stroke-linecap="round" />
									</g>
								</svg>
							</div>

							<img v-if="betslip.is_mission_betslip" src="/assets/white_sport_icons/Archery.svg" width="15" height="15" alt="" @click="Betslip_closed(betslip.betslip_id)" style="cursor: pointer;">

							<p @click="Betslip_closed(betslip.betslip_id)" style="cursor: pointer;">{{ betslip.betslip_type_lang }}</p>

							<template v-if="betslip.settlement || betslip.cashout_at">
								<p v-if="betslip.settlement == 'LOST' || betslip.settlement == 'HALF_LOST'" style="text-align:end; cursor: pointer; color: #D93444;" @click="Betslip_closed(betslip.betslip_id)">{{ betslip.settlement }}</p>
								<p v-if="betslip.settlement == 'WON' || betslip.settlement == 'HALF_WON'" style="text-align:end; cursor: pointer; color: #63A246;" @click="Betslip_closed(betslip.betslip_id)">{{ betslip.settlement }}</p>
								<p v-if="betslip.settlement == 'PUSH' || betslip.settlement == 'CANCELED' || betslip.settlement == 'REFUND'" style="text-align:end; cursor: pointer;" @click="Betslip_closed(betslip.betslip_id)">{{ betslip.settlement }}</p>
								<p v-if="betslip.cashout_at" style="text-align:end; cursor: pointer;">Cashed Out</p>
							</template>
							<template v-else><p @click="Betslip_closed(betslip.betslip_id)" style="cursor: pointer;"></p></template>
						</div>

						<div class="collapse-ico" @click="Betslip_closed(betslip.betslip_id)">
							<svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.414" viewBox="0 0 13.414 7.414">
								<g id="Group_165" data-name="Group 165" transform="translate(1676.207 587.207) rotate(180)">
									<line id="Line_5" data-name="Line 5" x2="6.034" y2="6" transform="translate(1663.5 580.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1" />
									<line id="Line_6" data-name="Line 6" x1="6.034" y2="6" transform="translate(1669.466 580.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1" />
								</g>
							</svg>
						</div>
					</div>

					<div class="myBets-items" v-for="bet in betslip.bets" :key="bet.id" @click="goToFixture(bet)" style="cursor:pointer;">
						<div class="top single-settled-top">
							<div v-if="bet.settlement">
								<img v-if="bet.settlement == 'WON' || bet.settlement == 'HALF_WON'" src="@/assets/images/light_circle_img.png" alt="WON">
								<img v-if="bet.settlement == 'LOST' || bet.settlement == 'HALF_LOST'" src="@/assets/images/pink_circle_img.png" alt="LOST">
								<img v-if="bet.settlement == 'PUSH' || bet.settlement == 'CANCELED' || bet.settlememt == 'REFUND'" src="@/assets/images/yellow_circle_img.png" alt="PUSH OR CANCELLED">
							</div>
							<div class="warning-ico" v-else>
								<img v-if="!bet.settlement && !bet.current_price" src="/assets/icons/warning.svg" width="16">
							</div>
							<div class="left">
								<div class="title">
									<div class="title-info">
										<span v-if="bet.live == true" class="btp-live">
											<div class="live-badge" v-html="svg_data.live_badge"></div>
										</span>
										<div class="btp-hour">
											<div class="btp-hour-inn">
												<template v-if="bet.live == true">
													<span v-if="liveSportsStore.hasFixtureClock(bet.sport_id)">{{ myBetsStore.fixtureClockTime(bet.statistics)}} {{ myBetsStore.fixtureState(bet.statistics)}}</span>
													<span v-else>{{ myBetsStore.fixtureState(bet.statistics)}}</span>
													<span class="btp-hours-progress">
														<span class="btp-progress-bar"></span>
													</span>
												</template>
												<template v-else>
													<span>{{ bet.market_name }}
												</span>
												</template>
											</div>
										</div>
									</div>
									<span>{{bet.market_name }}</span>
									<p class="btp-title">{{ bet.odd_name }}</p>
								</div>
							</div>

							<div class="right">
								<div class="rate">
									{{ bet.sp ? bet.placeholder : oddsStore.oddsFormat(bet.odd_price) }}
								</div>
							</div>

						</div>

						<div class="asia">
							<div class="icon">
								<img :src="liveSportsStore.liveSportWhiteIcon(bet.sport_id)" alt="card-image">
							</div>
							<div class="betSlip-live">
								<div class="detail">
									<p class="ad-tittle-top">{{ bet.region_name }} - {{ bet.competition_name }}</p>
									<p v-if="liveSportsStore.hasOneParticipant(bet.sport_id) || participantsSame(bet)" class="ad-tittle-btm">{{ bet.participants[0] }}</p>
									<p v-else class="ad-tittle-btm"><span>{{ bet.participants[0] }}</span> vs <span>{{ bet.participants[1] }}</span></p>
								</div>

							<div class="live-score" v-if="bet.live">
								<template v-if="liveSportsStore.hasOneParticipant(bet.sport_id) || participantsSame(bet)">
									<span v-if="bet.statistics">{{ bet.statistics.p1_score }}</span>
								</template>
								<template v-else>
									<span v-if="bet.statistics">{{ bet.statistics.p1_score }}</span>

									<span v-if="bet.statistics">{{ bet.statistics.p2_score }}</span>
								</template>
							</div>

							<div class="date-time" v-else>
								<p class="date">{{ datesStore.preFixtureBookDate(bet.event_datetime) }}</p>
								<p class="time">{{ datesStore.preFixtureBookTime(bet.event_datetime) }}</p>
							</div>

							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="bottom">
				<div class="reuturn">
					<div class="stake" v-if="betslip.betslip_stake > 0">
						<p> {{ $t('betslip.stake') }}:</p>
						<p :class="{ 'free_bet' : betslip.freebet_id > 0}"><span><svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 20.311 19.001"><g id="Group_1760" data-name="Group 1760" transform="translate(8326.008 8556.914)"><g id="g313" transform="translate(-8325.508 -8552.801)"><path id="path315" d="M-25.358,0H-41.4a1.636,1.636,0,0,0-1.636,1.636V3.924h19.311V1.636A1.636,1.636,0,0,0-25.358,0Z" transform="translate(43.033)" fill="none" stroke="#878a90" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"></path></g><path id="path317" d="M21.943-29.062H5.578V-18.6H21.943Z" transform="translate(-8329.613 -8519.814)" fill="none" stroke="#878a90" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"></path><g id="g319" transform="translate(-8315.853 -8552.801)"><path id="path321" d="M0,0V14.388" fill="none" stroke="#878a90" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"></path></g><g id="g323" transform="translate(-8315.853 -8556.414)"><path id="path325" d="M-5.817-5.185H-9.871l2.743-3.032A1.768,1.768,0,0,1-5.817-8.8,1.766,1.766,0,0,1-4.05-7.033v.082A1.766,1.766,0,0,1-5.817-5.185Z" transform="translate(9.871 8.798)" fill="none" stroke="#878a90" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"></path></g><g id="g327" transform="translate(-8321.674 -8556.414)"><path id="path329" d="M-2.536-5.185H1.519L-1.225-8.217A1.768,1.768,0,0,0-2.536-8.8,1.766,1.766,0,0,0-4.3-7.033v.082A1.766,1.766,0,0,0-2.536-5.185Z" transform="translate(4.303 8.798)" fill="none" stroke="#878a90" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"></path></g></g></svg></span> {{ currenciesStore.format_currency(betslip.betslip_stake)  }}</p>
					</div>
					<div class="stake" v-if="betslip.bonus_stake > 0">
						<p>{{ $t('betslip.bonus_stake') }}:</p>
						<p>{{ currenciesStore.format_currency(betslip.bonus_stake) }}</p>
					</div>

					<div v-if="!myBetsStore.hasSPOdd(betslip)" class="return">
						<p>{{ $t('betslip.to_return') }}:</p>
						<p>{{ currenciesStore.format_currency(betslip.to_return)  }}</p>
					</div>
					<div class="return" v-if="betslip.settlement || betslip.cashout_at">
						<p>{{ $t('my_bets.returned') }}</p>
						<p>{{ currenciesStore.format_currency(betslip.returned)  }}</p>
					</div>
				</div>
			</div>

			<div class="cashout-btn-outer" v-if="betslip.cashout_available && (betslip.mission_can_cashout == null || betslip.mission_can_cashout == true)">
				<template v-if="cashout_verification != betslip.betslip_id">
					<a href="#!"  @click="cashout_verification = betslip.betslip_id" class="btn-secondary cashout-btn betslip-button" style="cursor:pointer;">{{ $t('general.cash_out') }}: {{ currenciesStore.format_currency(betslip.cashout_amount)  }}</a>
				</template>
				<template v-else>
					<p v-if="betslip.mission_can_cashout == true" style="font-size: 13px; font-weight: 500; color: #F2F2F7; margin-bottom: 10px; text-align:center;">{{ $t('missions.mission_cashout_warning_text') }}</p>
					<p v-else style="font-size: 13px; font-weight: 500; color: #F2F2F7; margin-bottom: 10px; text-align:center;">{{ $t('general.are_you_sure') }}</p>
					<div class="cashout-btn-inner">
						<a v-if="cashout_processing==betslip.betslip_id" href="#!" class="btn-small btn-secondary" >
							<div class="loader-main"></div>
						</a>
						<a @click="cashoutBetslip(betslip)" href="#!" class="btn-small btn-secondary betslip-button" v-else>{{ $t('general.yes') }}</a>

						<a @click="cashout_verification = null"  class="btn-small btn-secondary betslip-button">{{ $t('general.no') }}</a>

					</div>
				</template>
			</div>
			<div v-else-if="betslip.cashout_at == null && betslip.betslip_result == 'settled'" >
			</div>
			<div v-else-if="betslip.cashout_at == null && betslip.settled_at == null" class="cashout-btn-outer">
				<a href="#!" class="btn-small-disabled" disabled>{{ $t('betslip.no_cashout_available') }}</a>
			</div>
		</div>
	</div>
</template>
<script setup>
 /* eslint-disable */
import { ref, onMounted, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useDatesStore } from '@/stores/dates';
import { useCurrenciesStore } from '@/stores/currencies';
import { useLanguagesStore } from '@/stores/languages';
import { MyBets } from '@/stores/MyBets';
import { useLiveSportsStore } from '@/stores/live_sports';
import moment from 'moment';
import commonSvgs from '@/assets/svgs/commonSvgs.json'
import { useOddsStore } from '@/stores/odds';
import Swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';

const collapse_closed = ref({});
const oddsStore = useOddsStore();
const datesStore = useDatesStore();
const currenciesStore = useCurrenciesStore();
const languagesStore = useLanguagesStore();
const myBetsStore = MyBets();
const liveSportsStore = useLiveSportsStore();
const router = useRouter();
const { t } = useI18n();

const props = defineProps({
	betData: {
		required: true,
	},
});

const svg_data = ref(commonSvgs);
const cashout_verification = ref(false);
const cashout_processing = ref(null);

const participantsSame = (bet) => {
	if (bet.participants[0] == bet.participants[1]) return true;
	return false;
}

const cashoutBetslip = (betSlip) => {
	if (betSlip.cashout_available == false) return;

	if (betSlip.mission_can_cashout == false) return;

	if (cashout_processing.value == betSlip.betslip_id) return;

	cashout_processing.value = betSlip.betslip_id;

	myBetsStore.cashoutBetslip({ betslip_id:betSlip.betslip_id, cashout_amount: betSlip.cashout_amount }).then(() => {
		cashout_processing.value = null;
	}).catch(() => {
		cashout_processing.value = null;
	});
}

const goToFixture = (bet) => {
	if (bet.live) {
		router.push({'name': 'live-sports-eventview', params: {'fixture_id': bet.fixture_id} });
	} else {
		if (moment(bet.event_datetime).isAfter(moment.now())) {
			router.push({ name: 'pre-sports-fixturebook', params: { sport_id: bet.sport_id, region_id: bet.region_id, competition_id: bet.competition_id, fixture_id: bet.fixture_id } } )
		}
	}
}

// Toggle Favourite
const toggleFavourite = (betslip_id) => {
	myBetsStore.toggleFavourite(betslip_id);
};

const Betslip_closed = (betslip_selected_id) => {
	if(collapse_closed.value[betslip_selected_id]){
		delete collapse_closed.value[betslip_selected_id]
	}
	else{
		collapse_closed.value[betslip_selected_id]= true
	}
};

// create computed to change the grid template collumns if betslip is mission
const gridColumns = computed(() => {
	return { display: 'grid', gridTemplateColumns: 'auto auto auto 1fr', gap: '6px', alignItems: 'center'}
});

</script>

<style scoped>
.mybets-betslips {
	max-height: 600px;
	overflow-y: auto;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.mybets-betslips {
		max-height: calc(100vh - 220px);
	}
}

.mybets-betslips::-webkit-scrollbar {
  width: 3px;
}

.mybets-betslips::-webkit-scrollbar-thumb {
  background-color: #8f54a0;
  border-radius: 2px;
  border: 3px solid linear-gradient(#e382bc 0%, #8d54eb 100%);
}


.btn-small-disabled {
	color: #4E545D;
	border: 1px solid #4E545D;
	text-decoration: none;
	text-align: center;
	width: 100%;
	display: block;
	cursor: pointer;
	max-width: 100%;
	margin: 0 auto;
	font-size: 12px;
	font-weight: 700;
	border-radius: 5px;
	padding: 9.5px 20px;
	box-sizing: border-box;
	transition: all 0.5s;
	text-transform: uppercase;
	cursor: default !important;
	pointer-events: none;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.btn-small-disabled {
		margin-top: 12px;
	}
}
.grid-columns {
	display: grid;
	grid-template-columns: 40px auto 1fr;
	gap: 2%;
	align-items: center;
}

.eventime_mobal_content .my_bet_wrap .my_bet_game .myBet-inner-content .game_detail .top.single-settled-top .btp-hour .btp-hour-inn p {
	font-size: 12px;
	font-weight: 400;
	color: #63A246;
	text-overflow: ellipsis;
	overflow: hidden;
}
/* .eventime_mobal_content .my_bet_wrap .my_bet_game .myBet-inner-content .game_detail .top.single-settled-top .btp-hour .btp-hour-inn span {
    height: 3px;
    background: #4E545D;
    width: 100%;
    display: block;
    margin: 0px auto;
    position: relative;
} */
/* .eventime_mobal_content .my_bet_wrap .my_bet_game .myBet-inner-content .game_detail .top.single-settled-top .btp-hour .btp-hour-inn span:before {
	content: "";
	background: #FFBB02;
	width: 100%;
	height: 100%;
	position: initial;
	left: 0;
	top: 0;
	display: block;
	background: linear-gradient(#FFBB02, #FFBB02) left/0% 100% no-repeat;
	animation: live-animation 0.6s ease-in-out infinite alternate;
	-webkit-animation: live-animation 0.6s ease-in-out infinite alternate;
} */

.eventime_mobal_content .my_bet_wrap .my_bet_game .myBet-inner-content .game_detail .top.single-settled-top .btp-title {
	padding-top: unset;
	padding-bottom: 3px;
}
</style>