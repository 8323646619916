import { defineStore } from 'pinia';
import _ from 'lodash';
import { useCustomerStore } from './customer';

export const useCasinoStore = defineStore('casinoStore', {
	state: () => ({
		brands: {},
		games: {},
		favourites: {},
		last_played_games: {},
		load_more_games: {},
		more_games_fetched: {},
		selected_provider: 'all',
		search_term: null,
		show_favourites: false,
		featured_slot_games: {},
		games_loading: false,
		show_more_providers: [],
		all_games_load_more: 10,
		total_games_per_provider: {},
		current_page : 1,
		games_loading_more: false,
		total_pages: 0
	}),
	getters: {
		getGames(state) {
			if (!state.games) return {};

			let games = _.cloneDeep(state.games);
			let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.slots.length > 0) ? useCustomerStore().customer.favourites.slots : null;

			games = _.map(games, game => {
				let favourite_record = _.find(favourites, { game_id: String(game.id) });

				return {
					...game,
					favourite: favourite_record ? true : false,
					favourite_timestamp: favourite_record ? favourite_record.created_at : null
				};
			});

			let favourite_games = _.filter(games, { 'favourite': true });
			favourite_games = _.orderBy(favourite_games, ['favourite_timestamp'], ['desc']);

			let not_favourite_games = _.filter(games, { 'favourite': false });
			games = [...favourite_games, ...not_favourite_games];

			if (this.search_term) {
				games = _.filter(games, game => game.name.toLowerCase().includes(this.search_term.toLowerCase()));
			}

			let grouped_games = state.sortGamesByBrand(_.groupBy(games, 'label'));

			_.forIn(grouped_games, (value, key) => {
				const index = this.show_more_providers.indexOf(key);
				if (index === -1) {
					grouped_games[key] = value.slice(0, 13); // Limit to the first 10 games if the provider is not in `show_more_providers`
				}
			});

			return grouped_games;
		},
		getGamesCount(state) {
			return Object.keys(state.games).length;
		},
		sortGamesByBrand:() => (result) => {
			const sorted_keys = Object.keys(result).sort();
			const sorted_object = {};

			for (const key of sorted_keys) {
				sorted_object[key] = result[key];
			}

			return sorted_object;
		},
		getFavouriteGames(state) {
			if (!state.favourites) return {};
			let games = _.orderBy(state.favourites, ['favourite_timestamp', 'name'], ['desc', 'desc']);
			const index = this.show_more_providers.indexOf('show_favourites_games');

			if (index === -1) {
				games = games.slice(0, 13);
			}

			return games;
		},
		brandNames(state) {
			if (!state.brands) return [];

			let brands = _.chain(state.brands).map(brand => ({  name: brand.name, slug: brand.slug })).value();

			if (state.getFavouriteGames && state.getFavouriteGames.length > 0) {
				return [{ name: 'All', slug: 'all' }, { name: 'Favourites', slug: 'favourites' }, ...brands];
			}

			return [{ name: 'All', slug: 'all' }, ...brands];
		},
		loadMoreBrandGames(state) {
			if (!state.load_more_games) return [];

			return state.load_more_games;
		},
		selectedProvider(state) {
			return state.selected_provider;
		},
		searchTerm(state) {
			return state.search_term;
		},
		featuredSlotGames(state) {
			if (!state.games) return {};

			let games = _.cloneDeep(state.featured_slot_games);
			let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.slots.length > 0) ? useCustomerStore().customer.favourites.slots : null;

			games = _.map(games, game => {
				let favourite_record = _.find(favourites, { game_id: String(game.id) });

				return {
					...game,
					favourite: favourite_record ? true : false,
					favourite_timestamp: favourite_record ? favourite_record.created_at : null
				};
			});

			let favourite_games = _.filter(games, { 'favourite': true });
			favourite_games = _.orderBy(favourite_games, ['favourite_timestamp'], ['desc']);

			let not_favourite_games = _.filter(games, { 'favourite': false });
			games = [...favourite_games, ...not_favourite_games];

			return _.filter(games, game => game.image_url !== null);
		},
		featuredSlotGamesFooter(state) {
			if (!state.games) return {};

			let games = _.cloneDeep(state.featured_slot_games);
			let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.slots.length > 0) ? useCustomerStore().customer.favourites.slots : null;

			games = _.map(games, game => {
				let favourite_record = _.find(favourites, { game_id: String(game.id) });

				return {
					...game,
					favourite: favourite_record ? true : false,
					favourite_timestamp: favourite_record ? favourite_record.created_at : null
				};
			});

			let favourite_games = _.filter(games, { 'favourite': true });
			favourite_games = _.orderBy(favourite_games, ['favourite_timestamp'], ['desc']);

			let not_favourite_games = _.filter(games, { 'favourite': false });
			games = [...favourite_games, ...not_favourite_games];

			return _.chain(games).cloneDeep().filter(game => game.image_url !== null).take(8).value();
		},
		brandLogo() {
			return {
				'Ainsworth': '/assets/icons/casino/brands/ainsworth.svg',
				'Amatic': '/assets/icons/casino/brands/amatic.svg',
				'Apex': '/assets/icons/casino/brands/apex.svg',
				'Apollo': '/assets/icons/casino/brands/apollo.svg',
				'Aristocrat': '/assets/icons/casino/brands/aristocrat.svg',
				'EGT': '/assets/icons/casino/brands/egt.svg',
				'Fast Games': '',
				'Fish': '',
				'GClub': '/assets/icons/casino/brands/gclub.svg',
				'Habanero': '/assets/icons/casino/brands/habanero.svg',
				'Igrosoft': '/assets/icons/casino/brands/igrosoft.svg',
				'IGT': '/assets/icons/casino/brands/igt.svg',
				'Kajot': '/assets/icons/casino/brands/kajot.svg',
				'Live dealers': '',
				'Merkur': '/assets/icons/casino/brands/merkur.svg',
				'Microgaming': '/assets/icons/casino/brands/microgaming.svg',
				'More expensive': '',
				'Netent': '/assets/icons/casino/brands/netent.svg',
				'Novomatic': '/assets/icons/casino/brands/novomatic.svg',
				'Platipus': '/assets/icons/casino/brands/platipus.svg',
				'Pragmatic': '/assets/icons/casino/brands/pragmatic.svg',
				'QuickSpin': '/assets/icons/casino/brands/quickspin.svg',
				'Scientific Games': '/assets/icons/casino/brands/scientific.svg',
				'Sport Betting': '',
				'Table Games': '',
				'Tomhorn': '/assets/icons/casino/brands/tomhorn.svg',
				'Wazdan': '/assets/icons/casino/brands/wazdan.svg',
			}
		},
		showFavouritesGames(state) {
			return state.show_favourites;
		},
		getLastPlayedGames(state) {
			if (!state.games) return {};

			let last_played_game_ids = _.map(state.last_played_games, 'game_id');

			// Filter the games based on the extracted game_ids
			let last_played_games = _.filter(state.games, game => {
				let game_id = null;
				if (process.env.VUE_APP_LICENSE_ENABLED == 'true') game_id = String(game.slot_id);
				else game_id = String(game.game_id);

				return _.includes(last_played_game_ids, game_id);
			});

			// Add favourite flag to the games
			let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.slots.length > 0) ? useCustomerStore().customer.favourites.slots : null;

			last_played_games = _.map(last_played_games, game => {
				let favourite_record = null;
				favourite_record = _.find(favourites, {'game_id': String(game.id)});

				return {
					...game,
					favourite: favourite_record ? true : false,
					favourite_timestamp: favourite_record ? favourite_record.created_at : null
				};
			});

			return _.orderBy(last_played_games, ['favourite_timestamp', 'name'], ['asc', 'asc']);
		},
		getShowMoreProvider(state) {
			return state.show_more_providers;
		},
		getTotalGamesPerProvider(state) {
			return state.total_games_per_provider;
		},
		getGamesLoading(state) {
			return state.games_loading;
		},
		getGamesLoadingMore(state) {
			return state.games_loading_more;
		},
		hasNextPage(state) {
			return state.current_page < state.total_pages ? true : false;
		}
	},
	actions: {
		fetchGames() {
			// If we're loading more games (i.e., page > 1), set games_loading_more
			if (this.current_page > 1) {
				this.games_loading_more = true;
			} else {
				this.games_loading = true;
			}

			let device = 'desktop';

			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				device = 'mobile';
			}
			let payload = {
				game_name: this.search_term,
				provider: this.selected_provider,
				page: this.current_page,
				device: device
			};

			return global.axios.post(process.env.VUE_APP_API_URL + '/slots/index', payload, { headers: { 'Version': 'v3' } }).then((response) => {
				if (this.current_page == 1) {
					// On page 1, reset the games list
					this.games = response.data['games'];
				} else {
					// For next pages, push new games to the existing list
					this.games.push(...response.data['games']);
				}

				this.brands = response.data['providers'];
				this.favourites = response.data['favourites'];
				this.total_games_per_provider = response.data['total_games_per_provider'] ?? 10;

				this.games_loading = false;
				this.games_loading_more = false; // Reset the "loading more" state
				this.total_pages = response.data['total_pages'];

				return Promise.resolve(response.data.data);
			}).catch((error) => {
				this.games_loading = false;
				this.games_loading_more = false; // Reset the "loading more" state
				console.log(error.response);

				return Promise.reject(error);
			});
		},
		fetchMoreBrandGames(provider) {
			let payload = {
				provider: provider
			};

			if (this.search_term) payload['game_name'] = this.search_term;

			payload['device'] = 'desktop';

			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				payload['device'] = 'mobile';
			}

			// Fetch only if not fetched before
			if (!Object.hasOwnProperty.call(this.more_games_fetched, provider)) {
				return global.axios.post(process.env.VUE_APP_API_URL + '/slots/more', payload, { headers: { 'Version': 'v3' } }).then((response) => {
					this.more_games_fetched[provider] = true;
					this.games = [...this.games, ...response.data['games']];

					return Promise.resolve(response.data);
				}).catch((error) => {
					this.games_loading = false;
					console.log(error.response);

					return Promise.reject(error);
				})
			} else {
				return Promise.resolve();
			}


		},
		fetchLastPlayedGames() {
			let payload = {
				device: 'desktop'
			};

			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				payload['device'] = 'mobile';
			}

			return global.axios.post(process.env.VUE_APP_API_URL + '/slots/last-played', payload).then((response) => {
				this.last_played_games = response.data;

				return Promise.resolve(response.data);
			}).catch((error) => {
				console.log(error);

				return Promise.reject(error);
			})
		},
		filterGames(brand_name, game_name) {
			this.fetchGames(brand_name, game_name);

			this.show_favourites = false;

			const index = this.show_more_providers.indexOf(brand_name);

			this.show_more_providers.splice(index, 1);
		},
		showFavourites() {
			this.search_term = null;
			this.show_favourites = true;
			this.show_more_providers = [];
		},
		openGame(game_id, demo = false) {
			let protocol = window.location.protocol;
			let hostname = window.location.hostname;
			let port = window.location.port ? window.location.port : '';
			let close_url = protocol + '//' + hostname + (port ? ':' + port : '') + '/casino/close';

			let payload = {
				game_id: game_id,
				close_url: close_url
			};

			if (demo) payload['demo'] = true;

			return global.axios.post(process.env.VUE_APP_API_URL + '/slots/opengame', payload).then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		fetchFeaturedSlotGames(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/slots/featured', payload, { headers: { 'Version': 'v3' } }).then((response) => {
				this.featured_slot_games = response.data.data;

				return Promise.resolve(response.data.data);
			}).catch((error) => {
				return Promise.reject(error);
			})
		},
		toggleFavourite(game_id) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/favourite/slot', { id: game_id }).then((response) => {
				useCustomerStore().customer.favourites = response.data.data;

				let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.slots.length > 0) ? useCustomerStore().customer.favourites.slots : null;

				let is_favourite = _.find(favourites, { game_id: String(game_id) });

				if (is_favourite) {
					let game = _.find(this.games, { 'id': game_id });
					this.favourites = [...this.favourites, game];
				} else {
					this.favourites = _.filter(this.favourites, game => game && game.id != game_id);
				}

				return Promise.resolve(response.data.data);
			}).catch((error) => {
				return Promise.reject(error);
			})
		},
		showMoreToggle(provider) {
			const index = this.show_more_providers.indexOf(provider);

			if (index !== -1) {
				// If the provider is already in the array, remove it
				this.show_more_providers.splice(index, 1);

				return Promise.resolve();
			} else {
				if (provider == 'show_last_played_games' || provider == 'show_favourites_games') {
					this.show_more_providers.push(provider);

					return Promise.resolve();
				}

				return this.fetchMoreBrandGames(provider).then(() => {
					this.show_more_providers.push(provider);

					return Promise.resolve();
				}).catch(() => {
					return Promise.reject();
				});
			}
		},
		// loadMoreGames() {
		// 	let games_count = Object.keys(_.groupBy(this.games, 'label')).length;

		// 	if (games_count > this.all_games_load_more) this.all_games_load_more += 10;
		// },
		loadMoreGames() {
			this.current_page++;
			this.fetchGames();
		},
		claimFreespinsAndPlay(freespin_id, slot_id) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/v2/account/freespins/claim', { freespin_id: freespin_id, slot_id: slot_id }).then(response => {

				return Promise.resolve(response.data);
			}).catch(error => {
				return Promise.reject(error);
			});
		},
		setGameProvider(provider) {
			this.selected_provider = provider != null ? provider : 'all';
			this.current_page = 1;
			this.fetchGames();
		},
		setSearchTerm(game_name) {
			this.search_term = game_name ?? null;
			this.current_page = 1;
			this.fetchGames();
		},
	},
})